<script>
	export let type = 'button';
	export let size = 'auto';
	export let href = '/';
</script>

<a {href}>
	<button {type} class={size}><slot /></button>
</a>

<style>
	button {
		background-color: #000;
		color: #fff;
		border: none;
		padding: 10px 20px;
		cursor: pointer;
		align-self: center;
	}

	.auto {
		width: auto;
	}

	.full {
		width: 100%;
	}

	.half {
		width: 50%;
	}

	.quarter {
		width: 25%;
	}
</style>
